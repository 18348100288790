.redLine {
    text-decoration-color: red;
}

.text-underline-hover {
    text-decoration: none;
}

.text-underline-hover:hover {
    text-decoration: underline;
    cursor: pointer;
}

.multine-ellipsis-1 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.multine-ellipsis-2 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.multine-ellipsis-3 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.font-bold {
    font-weight: bold;
}

h1 {
    font-size: 52px;
}

/* h2{
    font-size: 38px;
} */
p {
    font-size: 18px;
}

h4 {
    font-size: 22px;
}

.text-gradient-heading {
    background-image: linear-gradient(to right, #D82AFB, #30BDE3);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* font-size: 52px !important; */
}

.text-break-heading::after {
    content: "\a";
    white-space: pre;
}


.gradient-heading2 {
    /* font-size: 36px !important; */
    background-image: linear-gradient(to right, #D82AFB, #30BDE3);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.underline-gradient::before {
    content: "";
    position: absolute;
    top: 95%;
    width: 95%;
    left: 0;
    height: 4px;
    border-radius: 2px;
    /* Grandient line  i ahve romved */
    /* background: linear-gradient(to right, #9c27b0, #00bcd4); */
    /* padding-bottom: 2px; */
}

.underline-red {
    text-decoration: underline #B10421;
    text-decoration-thickness: 6px;

    /* content: "";
    position: absolute;
    top: 15%;
    width: 15%;
    left: 0;
    height: 1px;
    border-radius: 2px;
    background: linear-gradient(to right, red, red); */
    /* text-decoration-color: red; */
}

.underline-green {
    text-decoration: underline #06662B7D;
    text-decoration-thickness: 6px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: grey grey;
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    background-color: black;
    border: 1px solid black;
}

body::-webkit-scrollbar-thumb {
    background-color: grey;
}

/* MObile Screen */
@media only screen and (max-width: 600px) {
    h1 {
        font-size: 36px !important;
        /* / color: #B10421 !important; */
        line-height: 50px !important;
    }

    .brandingService {
        font-size: 24px !important;
    }

    .brandingConsultance {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    .hover-gradient-blogs {
        font-size: 18px !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: 40px !important;
        text-underline-position: auto !important;
    }

    h3 {
        font-size: 18px !important;
    }

    /* h4{
        font-size: 12px !important;
    } */
    p {
        font-size: 14px !important;
        line-height: 23px !important;
    }

    /* .row{
        margin-right: calc(0 * var(--bs-gutter-x)) !important;
    } */
    .homeImage img {
        display: none !important;
    }

    .homeHeaderContent h1 {
        font-size: 26px !important;
    }

    .navArrow {
        width: 50px !important;
    }

    .nav-gradient {
        font-size: 12px !important;
    }

    .cardStyling {
        width: 100% !important;

    }

    .countNumber {
        font-size: 25px !important;
    }

    .cardHeight {
        height: 250px !important;
    }

    .cardTxt h2 {
        font-size: 22px !important;
    }

    .counter {
        font-size: 40px !important;
    }

    /* .cardTxt{
        font-size: 20px !important;
    } */
    button {
        /* font-size: 12px !important; */
        font-size: 10px !important;

    }

    .images {
        width: 50% !important;
    }

    .images img {
        width: 100% !important;
    }

    .insightHeading h2 {
        font-size: 24px !important;
    }

    .Typewriter__wrapper {
        padding: 0px !important;
        margin: 0px !important;
        font-size: 12px !important;
    }

    /* Brand Development */
    .mainServiceImage img {
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .contentMargin {
        margin-left: 0px !important;
        width: 100% !important;
    }

    /* SERVICES OFFER */
    .sectionBorders {
        /* border-bottom: none !important;
        padding-bottom: 10px !important; */
        min-height: 170px !important;
    }

    .sectionBorderRight {
        border-right: none !important;
    }


    /* ABOUT US */
    .squad-image {
        width: 100% !important;
        height: 100% !important;
    }

    .squad-image img {
        width: 100% !important;
        height: 100% !important;
    }

    .squad-name {
        font-size: 13px !important;
    }

    .squad-designation {
        font-size: 18px;
    }

    /* PORTFOLIO MAIN PAGE */
    .portfolio-images img {
        width: 100%;
    }

    /* BLOGS */


    .blog-images img {
        width: 100%;
    }

    /* Contact Us */
    .contact-padding {
        padding: 0% !important;
    }

    /* -------------DRAWER NAVBAR------------------------- */
    .drawer-nav {
        padding: 0% !important;
    }

    /* -------------PORTFOLIO MAIN PAGE-------------------- */

    .portfolio-text h4 {
        font-size: 19px !important;
    }

    /* ------------Act of Kindness------------------------ */
    .function-text h2 {
        font-size: 16px !important;
    }

    .middle-text-title h4 {
        justify-content: center !important;
        font-size: 22px !important;
    }

    /* .middle-text-subtitle{

    } */
    .cardMargin {
        margin: 0 !important;
    }

    h4 {
        font-size: 24px !important;
    }

    .homeScreens img {
        width: 100% !important;
        height: 500px !important;
    }

    /* .row{
        margin-left: calc(-.0 * var(--bs-gutter-x)) !important;
    } */
    .function-div {
        width: 80px !important;
        height: 80px !important;
    }

    .function-div img {
        width: 60px !important;
        height: 30px !important;
    }

    .revmeMiddleImage {
        height: 70px !important;
        width: 70px !important;
    }

    .revmeMiddleImage img {
        height: 40px !important;
        width: 40px !important;
    }

    .main-row {
        margin-left: calc(-.0 * var(--bs-gutter-x)) !important;
    }

    .productUserHeading h3 {
        font-size: 22px !important;
    }

    .centerPadding {
        padding: 0 !important;
    }

    /* Join - AOK - Portfolio */
    .backgroundView {
        background-image: none !important;
        /* height: auto !important;
        width: auto !important; */

    }

    .headerLogo img {
        width: 45% !important;
    }

    /* -----------------CAROUSAL------------------- */
    .main-carousel:hover .child-carousel {
        left: 40% !important;
        top: 50% !important;

    }

    .next {
        right: 25% !important;
    }

    .prev {
        left: 21% !important;
    }



    /* ---------------------------------WELCOME SCREEN------------------------------ */
    .welcomeScreen {
        padding: 0 !important;
    }

    /* .brandService{
    font-size: 13px !important;
   } */

}

/* ---------------------------------------------------TABLET SCREENS--------------------- */
@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 38px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 12px !important;
        line-height: 26px !important;
    }

    /* p{
        font-size: 18px !important;
        line-height: 36px !important;
    } */
    .homeImage img {
        height: 400px !important;
    }

    .navArrow {
        width: 30px !important;
    }

    .fontChange {
        font-size: 18px !important;
    }

    .cardStyling {
        width: 100% !important;
        /* padding: 2% !important; */
        /* margin: 0 !important; */
    }

    .insightHeading h2 {
        font-size: 20px !important;
    }

    .insightDates h5 {
        font-size: 14px !important;
    }

    /* .countNumber{
        font-size: 40px !important;
    } */
    .countFont {
        font-size: 40px !important;
    }

    .mainServiceImage img {
        width: 100%;
    }

    /* .sectionBorders{
        min-height: 233px !important;
    } */
    .squad-image img {
        height: 210px;
    }

    .squad-name {
        font-size: 14px
    }

    .squad-designation {
        font-size: 12px;
    }

    .blog-images img {
        width: 100%;
        height: 100%
    }

    /* ----------------------------------------CASE STUDY SHADES----------------------- */
    .colorsShades {
        /* top: 0 !important; */
        width: 500px !important;
        height: 200px !important;
        filter: blur(100px) !important;
    }


}


/* ---------------------------------------------------TABLET SCREENS--------------------- */

@media only screen and (max-width: 820px) {
    h1 {
        font-size: 32px !important;
    }

    /* .sectionBorders{
        min-height: 233px !important;
    } */
    .squad-image img {
        height: 150px;
    }

    .squad-name {
        font-size: 12px;
    }

    .squad-designation {
        font-size: 14px;
    }

    .homeImage img {
        height: 300px !important;
    }

    .listFont h4 {
        font-size: 28px !important;
    }

    .middleText h4 {
        font-size: 28px !important;
    }

    .function-text h2 {
        font-size: 18px !important;
    }

    /* ----------------------JOIN_AOK_HEADER------------------------- */
    .headerMainHeading h2 {
        font-size: 38px !important;
        font-weight: bold !important;
    }

    .headerContent p {
        font-size: 22px !important;
    }

    .backgroundView {
        background-image: none !important;
        /* height: 1500px ; */
        height: auto !important;
        width: auto !important;
    }

    .mobileImage img {
        width: 70%;
    }

    .counter {
        font-size: 45px !important;
    }

    /* --------------------------CAROSUAL BUTTON CSS---------------------------------*/
    /* .arrow{
        width: 40px !important;
    } */
    /* .next{
        right: 37% !important;
    }
    .prev{
        left: 38% !important;
    } */

}

/* ---------------------------------------------------Mobile  SCREENS--------------------- */