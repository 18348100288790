/* .footerHeading{
  font-size: 18px;
  color: gray;
  font-family: 'Montserrat-Regular';
 } */
.newsletter{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
 border-radius: 200px;
 margin-top: 15px;
 }
 .newsletterImg{
 width: 20px;
 height: 25px;
 margin-right: 10px;
 margin-left: 20px;
 }
 .newsletterInput{
 flex: 1;
 border: none;
 outline: none;
 width: 100%;
font-size: 15px; 
}
 @media only screen and (max-width: 820px) {
  .newsletter{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
   border-radius: 200px;
   margin-top: 10px;
   }
   .newsletterImg{
   width: 20px;
   height: 20px;
   margin-right: 5px;
   margin-left: 5px;
   }

   @media only screen and (max-width: 600px) {
     .newsletterImg{
     width: 20px;
     height: 20px;
     margin-right: 10px;
     margin-left: 15px;
     }
    }
   /* .footerHeading{
    font-size: 12px;
    font-weight: 400;
  } */
 }
  /* ------------------------------ */
  /* .buttonIn {
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
  }
  
input {
    margin: 0px;
    width: 100%;
    outline: none;
    height: 50px;
    border-radius: 24px;
    border: 1px solid #ccc;
  }
  
.buttonIn button {
    position: absolute;
    border-radius: 24px;
    right: 8px;
    z-index: 2;
    width: 30%;
    border: none;
    top: 7px;
    height: 75%;
    cursor: pointer;
    color: white;
    background: linear-gradient(to right, #D82AFB, #30BDE3);
    transform: translateX(2px);
}

.buttonIn img{
  position: absolute;
  top:28%;
  left: 3%;
  width: 25px;
  height: 25px;
} */