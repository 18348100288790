
.animation {
    height:8.125rem;
    overflow:hidden;
   
  }
  
  .animation > div > div {
    padding: 0.25rem 0.3rem;
    height:11rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  
  .animation div:first-child {
    animation: text-animation 8s infinite;
  }
  .subtitle{
      margin-top: 2.81rem;
  }
  @keyframes text-animation {
    0% {margin-top: 0rem;}
    10% {margin-top: 0rem;}
    20% {margin-top: -6.62rem;}
    30% {margin-top: -5.62rem;}
    40% {margin-top: -18.24rem;}
    60% {margin-top: -17.24rem;}
    70% {margin-top: -17.24rem;}
    80% {margin-top: -17rem}
    90% {margin-top: 0rem;}
    100% {margin-top: 0rem;}
  }
/* Tablate Screen */
@media only screen and (max-width: 820px) {
  .animation {
    height:5.125rem;
    overflow:hidden;
  }
  
  .animation > div > div {
    padding: 0.25rem 0.3rem;
    height:7.81rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  .animation div:first-child {
    animation: text-animation 8s infinite;
  }
  .subtitle{
      /* margin-top: 2.81rem; */
  }
  @keyframes text-animation {
    0% {margin-top: 0rem;}
    10% {margin-top: 0rem;}
    20% {margin-top: -6.62rem;}
    30% {margin-top: -7.62rem;}
    40% {margin-top: -18.24rem;}
    60% {margin-top: -17.24rem;}
    70% {margin-top: -17.24rem;}
    80% {margin-top: -17rem}
    90% {margin-top: 0rem;}
    100% {margin-top: 0rem;}
  }

}
  /* Mobile Screen */
  @media (max-width: 600px) {
    .animation {
      height:9.125rem;
      overflow:hidden;
     
    }
    .animation > div > div {
      padding: 1.5rem 0.3rem;
      height:9.81rem;
      /* margin-bottom: 6.51rem;  */
       margin-bottom: 5.51rem; 
      display:inline-block;
    }
    
    .animation div:first-child {
      animation: text-animation 8s infinite;
    }
    .subtitle{
        margin-top: 2.81rem;
    }
    /* Adding new KeyFrames  */
   
  @keyframes text-animation {
    0% {margin-top: 0rem;}
    10% {margin-top: 0rem;}
    20% {margin-top: -6.62rem;}
    30% {margin-top: -7.62rem;}
    40% {margin-top: -18.24rem;}
    60% {margin-top: -17.24rem;}
    70% {margin-top: -17.24rem;}
    80% {margin-top: -17rem}
    90% {margin-top: 0rem;}
    100% {margin-top: 0rem;}
  }
  }

/* Small screen 400 */

  @media (max-width: 400px) {
    .animation {
      height:9.125rem;
      overflow:hidden;
     
    }
    .animation > div > div {
      padding: 1.7rem 0.5rem;
      height:11rem;
       margin-bottom: 6.51rem;  
       /* margin-bottom: 5.51rem;  */
      display:inline-block;
    }
    
    .animation div:first-child {
      animation: text-animation 8s infinite;
    }
    .subtitle{
        margin-top: 3rem;
    }
    /* Adding new KeyFrames  */
   
  @keyframes text-animation {
    0% {margin-top: 0rem;}
    10% {margin-top: 0rem;}
    20% {margin-top: -6.62rem;}
    30% {margin-top: -7.62rem;}
    40% {margin-top: -18.24rem;}
    60% {margin-top: -17.24rem;}
    70% {margin-top: -17.24rem;}
    80% {margin-top: -17rem}
    90% {margin-top: 0rem;}
    100% {margin-top: 0rem;}
  }   
  }
