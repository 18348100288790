.homeHeading{
    width: 73% !important;
}

.text-gradient-heading1{
    background-image: linear-gradient(to right, #D82AFB, #30BDE3);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 52px !important;
  }