.creativity-gradient{
    /* font-size: 36px !important; */
    background-image: linear-gradient(to right, #D82AFB, #30BDE3);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .create{
    /* font-size: 36px !important; */
  }
  .text-gradient-hover-sec{
    color: white;
  }
  .text-gradient-hover-sec:hover {
    background-image: linear-gradient(to right, #D82AFB, #30BDE3);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  .fontChange{
    font-family: "Monument-Bold";
    /* font-size: 24px; */
  }
  .first-col.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  