.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.accordion {
    /* --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"); */
    --bs-accordion-btn-active-icon: url("https://img.icons8.com/small/512/expand-arrow.png");
    /* --bs-accordion-btn-icon: url("https://icons.veryicon.com/png/o/miscellaneous/simple-and-round-line-mark/down-arrow-56.png"); */
    --bs-accordion-btn-icon: url("https://img.icons8.com/small/512/expand-arrow.png");
}

.accordion-button {
    border-radius: 50px !important;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    background-color: transparent !important;
    padding-left: 0px;
}
.accordion-flush .accordion-item{
    background-color: transparent !important;
}
.accordion-body{
    padding-left: 0px;
    color: white;
}
.accordion-button::after {
    filter: invert(1);
}