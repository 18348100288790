html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus{
  outline: none !important;
  box-shadow: none;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}


@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(./assets/fonts/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne-Medium';
  src: local('Syne-Medium'), url(./assets/fonts/Syne-Medium.ttf) format('truetype');
}
/* @font-face {
  font-family: 'Monument-Bold';
  src: local('Montserrat-Extended'), url(./assets/fonts/monument-extended.ttf) format('truetype');
} */
@font-face {
  font-family: 'Monument';
  src: url('./assets/fonts/MonumentExtended-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Monument-Bold';
  src: url('./assets/fonts/MonumentExtended-Ultrabold.otf') format('opentype');
}

.reg-font {
  font-family: 'Montserrat-Regular';
}
.bold-font {
  font-family: 'Monument-Bold';
}
.rob-font{
  font-family:  'Roboto';
}
.semi-poppins{
  font-family: 'Poppins';
}
.light-poppins{
  font-family: 'Poppins-Light';
}
.regular-poppins{
  font-family: 'Poppins-Regular';
}
.monument-extended{
  font-family: 'Monument';
}
.syne-medium{
  font-family: 'Syne-Medium';
}