/* body{
  background-color: black;
} */
.main-container{
    background: linear-gradient(to right, #D82AFB , #30BDE3);
}
.animated-column{
    /* animation-timing-function: linear; */
    background-color: black;
    animation-name: animation;
    /* animation-delay: 1s; */
    animation-duration: 0.8s;
}

@keyframes animation {
  0% {
    transform: translateX(-200%);
  }

  60% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(0%);
  }
}

.text-gradient-hover {
  font-size: 43px;
  font-weight: bold;
  color: white;
}

.text-gradient-hover:hover {
  background-image: linear-gradient(to right, #D82AFB, #30BDE3);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}