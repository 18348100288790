/* .main-carousel {
    display: flex;
    align-items: center;
    height: 255px;
}

.main-carousel img:hover {
    opacity: 0.2;
}

.carousal-image:hover img {
    opacity: 0.2;
}

.main-carousel:hover .child-carousel {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 50%;
    top: 50%;
}

.main-carousel {
    position: relative;
}

.child-carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
} */

.carousal-button {
    border-radius: 5px;
    background: transparent;
    border: 1px solid white;
    color: white
}












@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slide img {
    /* width: 20rem; */
    margin: 0 auto;
}

.slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
    

}



.activeSlide {
    opacity: 1;
    transform: scale(1.15);
    /* background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%) */
    
}

.arrow {
    cursor: pointer;
    height: 25px;
    width: 72px;
    position: absolute;
    z-index: 10;
}

.arrow img {
    transition: color 300ms;
    height: 25px;
    width: 72px;
}


/* .arrow svg:hover {
  color: #68edff;
} */

.next {
    top:105%;
    right:43%;
}

.prev {
    top:105%;
    left:43%;
}


.activeSlide:hover img {
    /* opacity: 0.2; */
    
}


/* .child-carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%); 
    transition: transform 0.5s ease, opacity 0.5s ease;
    
}
    */

/* .activeSlide:hover .child-carousel {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 50%;
    top: 50%;
    width: 101%;
   
}  */




 /* .child-carousel {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%) translateY(50px); 
    padding: 10px;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    text-align: center; 
  } */
  
  
  .slick-track{
    height: 390px;
}

.slick-slide div{
    height: 100%;
}

.slick-slide {
    padding: 30px 0px;
}

.child-carousel {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%); 
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    justify-content: flex-end;
    transition: transform 1s ease, opacity 1s ease;
    text-align: center;
    width: 101%;
    background: linear-gradient(0deg, rgb(21, 21, 21) 3%, rgba(255, 255, 255, 0) 100%);
}

.activeSlide:hover .child-carousel {
    transform: translate(-50%, 0); 
    opacity: 1;
}
