.border {
    /* border-width: 1.5px !important; */
    /* border-image: linear-gradient(to right, #9c27b0, #00bcd4) 1  !important; */
    background: linear-gradient(black, black) padding-box, linear-gradient(to right, #9c27b0, #00bcd4) border-box !important;
    border: 1.5px solid transparent !important;
}
.transparent-border{
    background: linear-gradient(white,white) padding-box, linear-gradient(to right, #9c27b0, #00bcd4) border-box !important;
    border: 1.5px solid transparent !important;
}

.simple-hover-effect:hover {
    background: white !important; 
    color: black !important;
}


.gradient-hover-effect:hover .gradient-text {
    background-image: linear-gradient(to right, #D82AFB, #30BDE3) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
    /* transition: 1.5s !important; */
}

.gradient-hover-effect:hover {
    background: white !important
}