/* .formControl{
    height: 35px !important;
    width: 80px !important;
}
.selected {
    color: white !important;
  }
 .btn{
    background-color: #6744F3;
    border-color: transparent;
    font-size: 1rem !important;
    font-family: 'Montserrat-Regular' !important;
}
.btn:hover{
    background-image: linear-gradient(to right, #D82AFB, #30BDE3) !important;
    border-color: none;

}
.dropdown-item{
    font-size: 1rem !important;
    font-family: 'Montserrat-Regular' !important;
} */
.form-select{
    font-size: 1rem !important;
    font-family: 'Montserrat-Regular' !important;
    font-weight: bold;
}


