.input-field2{
    background-color: transparent !important;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-radius: 0 !important;
    color: white !important;
    padding: 0;
    width: 100% !important;
    line-height: 2.5;
    
}
/* .form-control{
    background-color: ; */
/* } */
.input-field2:focus {
    color: white;
    background-color: transparent;
    border-color: white;
    outline: 0;
    box-shadow: none;

}
.MuiChip-label{
    color: white !important;
  }
  .MuiAutocomplete-tag{
    background-color: #303030 !important;
  }
  
  
  
  .MuiSvgIcon-root{
    color: white !important;
  }

  .form-control {
    background-color: transparent !important;
    height: 57px !important;
     width: 100% !important;
     border-color: white !important;
    }


  .react-tel-input {
   
    /* margin-top: 10px !important; */
    /* margin-left: 10px !important; */
  }
  .flag-dropdown {
    background-color: white !important;
    border-radius: 1px solid white;
  }
  .MuiFormLabel-root{
    font-size: 0.9rem !important;
    font-family: 'Montserrat-Regular' !important;
  }