.blogs-images img{
    background-color: black;
}
.blogs-images img:hover{
    cursor: pointer;
    opacity: 0.8;
}

.hover-gradient-blogs:hover {
    background-image: linear-gradient(to right, #D82AFB, #30BDE3) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
  }