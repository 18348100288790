.underline-gradient {
    position: relative;
  }
.underline-gradient::before {
    content: "";
    position: absolute;
    top: 95%;
    width: 95%;
    left: 0;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(to right, #9c27b0, #00bcd4);
  }

  .no-underline{
    width: 0%;
  }